import _ from "lodash";
//适配不同屏幕
(function (doc, win) {
    //用原生方法获取用户设置的浏览器的字体大小(兼容ie)
    var user_webset_font
    if (doc.documentElement.currentStyle) {
        user_webset_font = doc.documentElement.currentStyle['fontSize'];
    } else {
        user_webset_font = getComputedStyle(doc.documentElement, false)['fontSize'];
    }

    //取整后与默认16px的比例系数
    var xs = parseFloat(user_webset_font) / 16;
    //设置rem的js设置的字体大小
    var view_jsset_font, result_font;

    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        clientWidth,
        recalc = _.debounce(function () {
            clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (!doc.addEventListener) return;
            if (clientWidth < 1000) {
                //设置rem的js设置的字体大小
                view_jsset_font = 100 * (clientWidth / 1000);
                //最终的字体大小为rem字体/系数
                result_font = view_jsset_font / xs;
                //设置根字体大小
                console.log("result_font", result_font);
                docEl.style.fontSize = result_font + 'px';

                //下面代码修复低版本UC浏览器问题
                var style = document.getElementById("forhtml");
                if (style) {
                    style.parentNode.removeChild(style);
                }
                style = document.createElement("style");
                style.id = "forhtml";
                document.head.appendChild(style);

                style.appendChild(document.createTextNode("html{font-size:" + result_font + "=px !important;}"));
            } else {
                docEl.style.fontSize = 100 + 'px';
            }
        }, 300);

    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);