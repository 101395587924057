import config from "./config";
import axios from "axios";

const obj = {
    baseURL: config.apiBase,
    httpOption: {
        headers: {"Content-Type": "application/json"},
        /*        headers: {'X-Requested-With': 'XMLHttpRequest'},
                    timeout: 10 * 1000,
                    responseType: 'json',
                    maxContentLength: 200000,*/
    },

    setHeader(key, value) {
        this.httpOption.headers[key] = value;
    },
    async http(method, url, requestData, option) {
        let opt = Object.assign(
            {
                method,
                url: this.baseURL + url,
                data: requestData,
                params: requestData,
            },
            this.httpOption,
            option
        );
        if (method === "post") {
            opt.params = undefined;
        }

        let res = null,
            errMsg = null;
        try {
            res = await axios(opt);
        } catch (e) {
            console.log(e);
            errMsg = "访问服务器异常，请检查网络或者服务器连接！";
        }
        //返回错误信息 res = {config, data, headers, request, status}
        if (!res || res.status !== 200) {
            errMsg = "访问服务器异常，请检查网络或者服务器！";
        }
        //data: {code, data, msg}
        else if (
            !res.data ||
            typeof res.data !== "object" ||
            res.data.code === undefined
        ) {
            errMsg = "服务器返回数据结构错误";
        } else if (res.data.code === 401) {  //token有问题，需要重新登录
            //location.reload();
        } else if (res.data.code !== 200) {
            errMsg = res.data.msg || "服务器返回错误的空消息";
        }
        if (errMsg) {
            throw errMsg;
        }
        return res.data.data;
    },

    async downfile(method, url, requestData, option) {
        let opt = Object.assign(
            {
                method,
                url: this.baseURL + url,
                data: requestData,
                params: requestData,
            },
            this.httpOption,
            option
        );
        if (method === "post") {
            opt.params = undefined;
        }

        let res = null,
            errMsg = null;
        try {
            res = await axios(opt);
        } catch (e) {
            console.log(e);
            errMsg = "访问服务器异常，请检查网络或者服务器连接！";
        }
        if (!res || res.status !== 200) {
            errMsg = "访问服务器异常，请检查网络或者服务器！";
        }else if (
            !res.data ||
            res.data.code === 500
        ) {
            errMsg = "数据格式错误或返回数据为空";
        } else if (res.data.code === 401) {  //token有问题，需要重新登录

        }
        if (errMsg) {
            throw errMsg;
        }
        return res.data;
    },

    async get(url, requestData, option) {
        return this.http("get", url, requestData, option);
    },

    async post(url, requestData, option) {
        return this.http("post", url, requestData, option);
    },

    /**
     * 获取分页数据
     * @param url
     * @param condition
     * @param pagination ： {current:'当前请求第几页', pageSize:'每页条数'}
     * @param sorter: {sortField: '排序字段名', sortAsc: '是否升序'}
     * @param total_num: 是否需要返回总条数
     * @returns {Promise<*>}
     */
    async getObjArr(
        url,
        condition = null,
        pagination = null,
        sorter = null,
        total_num = false
    ) {
        let req = {...condition, total_num};
        if (pagination) {
            req.page = pagination.current;
            req.page_size = pagination.pageSize;
        }
        if (sorter) {
            req.sort_field = sorter.sortField;
            req.sort_asc = sorter.sortAsc;
        }
        return this.http("post", url, req);
    },

    /**
     * 获取分页数据
     * @param url
     * @param condition
     * @param pagination ： {current:'当前请求第几页', pageSize:'每页条数'}
     * @param sorter: {sortField: '排序字段名', sortAsc: '是否升序'}
     * @param total_num: 是否需要返回总条数
     * @returns {Promise<*>}
     */
    async getObjArrNew(
        url,
        condition = null,
        pagination = null,
        sorter = null,
        total_num = false
    ) {
        let req = {};
        req.condition = {...condition}
        req.paging = {total_num};
        if (pagination) {
            req.paging.page_size = pagination.pageSize;
            req.paging.page = pagination.current;
        }
        if (sorter) {
            req.paging = Object.assign(req.paging, sorter);
        }
        return this.http("post", url, req);
    },

};


export default obj;
