import md5 from "js-md5";
import CryptoJS from "crypto-js";

const obj = {
    encrypt(data) {
        const encrypted = CryptoJS.enc.Utf8.parse(data);
        const cipher = CryptoJS.enc.Base64.stringify(encrypted);
        return cipher;
    },

    decrypt(data) {
        const decrypted = CryptoJS.enc.Base64.parse(data);
        const cipher = decrypted.toString(CryptoJS.enc.Utf8);
        return cipher;
    },


    /***
     * 求md5，函数，使用：md5(plainText)
     */
    md5: md5,


    /***
     * 压缩图片
     * @param dataUrl 图片文件dataUrl
     * @param maxwidth 压缩后的最大宽度
     * @param maxheight 压缩后的最大高度
     * @param mime 压缩成的图片文件格式
     * @returns {Promise<{dataUrl, blob}>}
     */
    compressImage(dataUrl, maxwidth, maxheight, mime) {
        return new Promise((resolve, reject) => {
            const sourceImage = new Image();
            sourceImage.src = dataUrl;
            sourceImage.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                if (!ctx) {
                    reject(`No canvas context available`);
                    return;
                }
                let w = sourceImage.naturalWidth;
                let h = sourceImage.naturalHeight;
                let xratio = maxwidth ? maxwidth / w : 1;
                let yratio = maxheight ? maxheight / h : 1;
                let ratio = Math.min(xratio, yratio, 1);
                canvas.width = w * ratio;
                canvas.height = h * ratio;
                ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
                const dataUrl = canvas.toDataURL(mime || "image/jpg", 1);
                canvas.toBlob(
                    (blob) => {
                        resolve({
                            dataUrl: dataUrl,
                            blob: blob,
                        });
                    },
                    mime || "image/jpg",
                    1
                );
            };
        });
    },
}

export default obj;
