import net from "@/utils/net";
import * as OSS from "ali-oss";

const imageUploader = {
    async getToken() {
        return net.post("/public/upload", {});
    },

    async uploadfileList(uploadFiles, mime) {
        if (uploadFiles.length <= 0) return [];
        const ossInfo = await this.getToken();

        const client = new OSS(ossInfo);
        const all = [], prefix = ossInfo.prefix || "";
        if (Array.isArray(mime)) {
            for (let r = 0; r < uploadFiles.length; r++) {
                // console.log(obj.name);
                all.push(client.put(prefix + uploadFiles[r].name, uploadFiles[r].blob, {
                    headers: {"Content-Type": mime[r]},
                }));
            }
        } else {
            for (const obj of uploadFiles) {
                // console.log(obj.name);
                all.push(client.put(prefix + obj.name, obj.blob, {
                    headers: {"Content-Type": mime},
                }));
            }
        }
        return await Promise.all(all);
    },
};

export default imageUploader;
