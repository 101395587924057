import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_uploader = _resolveComponent("van-uploader");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_uploader, {
    modelValue: $data.myImages,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.myImages = $event),
    "max-count": 9,
    "after-read": $options.upload,
    accept: ".jpg,.jpeg,.png",
    multiple: ""
  }, null, 8, ["modelValue", "after-read"])]);
}