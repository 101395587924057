import {reactive} from "vue";
import net from "@/utils/net";

class Service {
    constructor() {
    }

    async http_getCatDetail(id) {
        return net.post("/public/car/info", {car_id: id});
    }

    async http_car_use(id) {
        return net.post("/public/car/use", {car_id: id});
    }

    async http_car_return(params) {
        return net.post("/public/car/return", params);
    }
}

export default reactive(new Service())