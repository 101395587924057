import util from "@/utils/util";
import imageUploader from "./image_upload";
export default {
  props: {
    upLoadImages: Array,
    default: []
  },
  components: {},
  data() {
    return {
      myImages: this.upLoadImages
    };
  },
  methods: {
    async upload(e) {
      let uploadList = [];
      console.log(e);
      if (Array.isArray(e)) {
        uploadList = e;
      } else {
        uploadList = [e];
      }
      for (let event of uploadList) {
        event.status = "uploading";
        const reader = new FileReader();
        reader.onload = async evt => {
          //压缩图片
          let fileType = event.file.type;
          let obj = await util.compressImage(evt.target.result, self.max_width, self.max_height, event.file.type);
          let name = util.md5(obj.dataUrl) + '_' + util.encrypt(event.file.name).replace(/[/]/g, '-');
          let uploadFile = {
            name: name,
            dataUrl: obj.dataUrl,
            blob: obj.blob,
            uploaded: false
          };
          let arr;
          try {
            arr = await imageUploader.uploadfileList([uploadFile], [fileType]);
          } catch (e) {
            console.log("", e);
          }
          event.url = arr[0].url;
          event.status = "done";
          console.log("!!!!!", arr);
        };
        console.log(this.myImages);
        await reader.readAsDataURL(event.file);
      }
    },
    getImageList() {
      return this.myImages;
    },
    reset() {
      this.myImages = [];
    }
  }
};