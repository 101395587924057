import "core-js/modules/es.array.push.js";
import service from "./service";
import ImageEmpty from "@/assets/empty.png";
import ImageUpload from "../components/ImageUpload";
import { showSuccessToast, showFailToast, showConfirmDialog } from 'vant';
import 'vant/es/toast/style';
import "vant/es/dialog/style";
export default {
  components: {
    ImageUpload
  },
  data() {
    return {
      showModal: false,
      id: "",
      detail: null,
      showLoading: true,
      errorText: "",
      ImageEmpty,
      params: {
        after_mileage: null,
        memo: ""
      },
      imageList: []
    };
  },
  created() {
    let params = this.getUrlParams();
    console.log("params", params);
    if (params && params.id) {
      this.id = params.id;
    } else {
      this.errorText = "车辆信息错误";
    }
  },
  async mounted() {
    if (this.id) {
      await this.getDetail();
    }
  },
  computed: {
    showDetail() {
      let detail = this.detail;
      if (!detail) {
        return [];
      }
      return [{
        title: "车牌号:",
        value: detail.car_num
      }, {
        title: "车牌类型:",
        value: detail.car_type
      }, {
        title: "当前里程:",
        value: (detail.odometer || 0) + "公里"
      }, {
        title: "车辆状态:",
        value: this.carStatus(detail.status)
      }];
    }
  },
  methods: {
    validator(val) {
      if (val <= 0) {
        return "里程数要大于0";
      }
      if (!this.detail || !this.detail.odometer) {
        return true;
      }
      return true;
    },
    onCancel() {
      this.showModal = false;
    },
    btnStatusStyle() {
      let status = this.detail && this.detail.status;
      switch (status) {
        case 1:
          return "";
        case 2:
          return "car-return";
        default:
          return "car-error";
      }
    },
    btnStatus() {
      let status = this.detail && this.detail.status;
      switch (status) {
        case 1:
          return "借车";
        case 2:
          return "还车";
        default:
          return "未知状态";
      }
    },
    carStatus(status) {
      switch (status) {
        case 1:
          return "闲置";
        case 2:
          return "借出";
        default:
          return "未知状态";
      }
    },
    async onUseCar() {
      if (this.detail.status === 1) {
        showConfirmDialog({
          title: '用车提示',
          message: '是否确定使用该车辆'
        }).then(async () => {
          await this.http_car_use();
        }).catch(() => {
          // on cancel
        });
      } else {
        this.params.after_mileage = 0;
        this.params.memo = "";
        this.imageList = [];
        this.showModal = true;
      }
    },
    async handleOk() {
      let params = this.params;
      let imageList = this.$refs.imageUpload && this.$refs.imageUpload.getImageList() || [];
      let isFinish = true;
      console.log(imageList);
      for (let image of imageList) {
        if (image.status && image.status !== 'done') {
          isFinish = false;
          break;
        }
      }
      if (!isFinish) {
        showFailToast("请先等待图片上传完毕");
        return;
      }
      params.images = this.imageListToString(imageList);
      params.car_id = this.id;
      this.showLoading = true;
      try {
        let result = await service.http_car_return(params);
        console.log(result);
        this.$refs.imageUpload && this.$refs.imageUpload.reset();
        await this.getDetail();
        //  message.success("还车成功");
        showSuccessToast('还车成功');
      } catch (e) {
        console.error(e);
        //    message.error(e);
        showFailToast(e);
      } finally {
        this.showModal = false;
        this.showLoading = false;
      }
    },
    imageListToString(array) {
      if (!array || array.length === 0) {
        return "";
      }
      let list = [];
      for (let image of array) {
        list.push(image.url);
      }
      return list.join(",");
    },
    getUrlParams() {
      let url = window.location.href;
      let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/ig;
      let result = {};
      url.replace(pattern, ($, $1, $2) => {
        result[$1] = $2;
      });
      return result;
    },
    async getDetail() {
      this.showLoading = true;
      try {
        let result = await service.http_getCatDetail(this.id);
        this.detail = result;
        console.log(result);
        if (result && result.group && result.group.group_type === 1) {
          this.errorText = "办公用车请用小程序扫码使用";
        }
      } catch (e) {
        this.errorText = e;
      } finally {
        this.showLoading = false;
      }
    },
    async http_car_use() {
      this.showLoading = true;
      try {
        let result = await service.http_car_use(this.id);
        this.showLoading = false;
        console.log(result);
        await this.getDetail();
        //  message.success("开始用车");
        showSuccessToast('开始用车');
      } catch (e) {
        this.showLoading = false;
        //   message.error(e);
        showFailToast(e);
      }
    }
  }
};